div.blocked {
  pointer-events: none;
}
.ui_top {
  text-align: center;
  background-color: #f6f9ff;
  overflow: hidden;
  height: 92px;
  position: relative;
  display: flex;
  justify-content: space-between;
}
.study_info {
  float: left;
  text-align: left;
  padding: 10px;
  font-size: 15px;
}
.study_info div {
  text-wrap: nowrap;
  max-width: 27vw;
  overflow: hidden;
}

.ui_buttons {
  padding: 10px;
  position: relative;
  top: 0;
}

.play_buttons span {
  font-size: 30px;
  color: grey;
  cursor: pointer;
}
.play_buttons span:hover {
  color: yellow;
}
.play_buttons span.selected {
  color: yellow;
}
.play_buttons span.active {
  color: rgb(0, 47, 255);
}
.play_buttons .move_right_button {
  transform: rotateZ(-140deg);
}
.bottom_buttons {
  display: inline-block;
  position: relative;
  left: -122px;
}

.bottom_buttons button {
  margin-left: 10px;
}

#add_event_button {
  position: relative;
  left: 0px;
}

.chart {
  display: flex;
  flex-direction: row;
  position: absolute;
  width: 100vw;
  left: -103px;
}

.chart .chartNames {
  flex-direction: column;
  display: flex;
  margin-top: 5px;
  min-width: 77px;
}
.chart #chartContainer {
  flex-grow: 1;
  min-height: 600px;
  margin-top: 5px;
}

#chartContainer .canvasResize {
  cursor: e-resize;
}
.chart .chartNames .chartName {
  flex-grow: 2;
  height: 0;
  align-items: center;
  flex: 2 1 0;
  display: flex;
  justify-content: center;
  cursor: pointer;
  position: relative;
  z-index: 1000;
}
.chart .chartNames .zoomband {
  flex-grow: 1;
}
.chart .chartNames .chartName div.chartTitle {
  overflow: hidden;
  transform: scale(-1, -1);
  writing-mode: vertical-rl;
  font-size: 17px;
}
.chart .chartNames .chartName div.chartTitleType {
  overflow: hidden;
  transform: scale(-1, -1);
  writing-mode: vertical-rl;
  font-size: 10px;
  left: -3px;
  position: relative;
}
.chart .chartName svg {
  cursor: pointer;
}
.chart .chartNames .chartName .chartContainer {
  display: flex;
  flex-direction: row;
  z-index: 100;
  height: 100%;
  align-items: center;
  left: 10px;
  position: relative;
}
.chart .chartNames .chartName .chartIcons {
  display: flex;
  flex-direction: column;
  margin-left: 5px;
}
.chart .chartNames .chartName #zoombandPosition .chartIcons {
  margin-left: 25px;
}

.chartName.dragged {
  z-index: 10;
}

.unusedCharts {
  display: flex;
  flex-direction: row;
  position: absolute;
  top: -52px;
  padding: 10px 6px;
  min-width: 80px;
  min-height: 40px;
  left: 229px;
  z-index: 1000;
}
.active.unusedCharts {
  z-index: 10;
}
.unusedCharts .chartName {
  margin: 0 8px;
}

.unusedCharts.dragHover {
  background-color: #fbfb16;
}
.unusedCharts:hover {
  background-color: #fbfb168b;
}

.chartPosition {
  position: relative;
  height: 0;
  z-index: 10000;
}
.chartPosition div {
  position: absolute;
  height: 20px;
  background-color: none;
  width: 200%;
  top: -10px;
  border-radius: 11px;
}
.chartPositionInside:hover {
  background-color: #dddd1575;
}
.chartSettings {
  display: flex;
  flex-direction: row;
}

#color_picker_container {
  margin-left: 20px;
}

#color_picker_container button {
  margin-top: 20px;
}

.chartSettings .chartSettingsFieldsRow {
  margin-bottom: 10px;
}
.chartSettings .chartSettingsFieldsRow span {
  display: inline-block;
}
.chartSettings .chartSettingsFieldsRow .chartSettingsFieldLabel {
  min-width: 130px;
}
.chartSettings .chartSettingsFieldsRow #color_picker_small {
  display: inline-block;
  width: 15px;
  height: 15px;
  min-width: 10px;
  cursor: pointer;
  position: relative;
  top: 3px;
}

.searchEventButtons span {
  margin: 0 5px;
  cursor: pointer;
}

.eventDatePickerLabel {
  display: inline-block;
  width: 125px;
  margin-bottom: 12px;
}

.add_event_cursor canvas {
  cursor: ew-resize;
}
.play_mode_button {
  position: relative;
  left: 5px;
}
.event_types_selector {
  position: relative;
  left: 100px;
}
.time_interval_mode_button {
  left: 10px;
}
.eventHover {
  position: fixed;
  z-index: 1000;
  background-color: #efe1e2;
  opacity: 0.9;
  display: inline-block;
  min-height: 50px;
  width: 500px;
}
.eventHover.artifactHover {
  width: 300px;
}
.eventHoverDrawing {
  min-height: 30px;
  background-color: #9facc5;
  width: 150px;
}
#eventHoverChart {
  display: inline-block;
  margin: 10px;
  width: 200px;
  height: 200px;
  background-color: green;
  vertical-align: top;
}
.eventHover.artifactHover #eventHoverChart {
  display: none;
}
.eventHoverDrawing #eventHoverChart {
  display: none;
}
.eventHover .eventHoverText {
  display: inline-block;
}
.eventHover .eventHoverText .eventHoverTextLabel {
  display: inline-block;
  min-width: 120px;
  text-align: right;
  margin-right: 10px;
  font-size: 12px;
  font-weight: bold;
  vertical-align: top;
  inline-size: min-content;
}

.eventHover .eventHoverText .eventHoverTextValue {
  display: inline-block;
  font-size: 12px;
  text-align: left;
  inline-size: min-content;
  padding-right: 10px;
  vertical-align: top;
  min-width: 130px;
}
.redo_buttons {
  display: inline-block;
  float: left;
  font-size: 25px;
  display: none;
}
.redo_buttons span {
  cursor: pointer;
  margin-left: 15px;
}
.redo_buttons svg {
  transform: rotateZ(180deg);
}
.redo_buttons .disabled {
  color: #dfdfdf;
}
.revert_button {
  position: relative;
  font-size: 12px;
  height: 30px;
  width: 30px;
  overflow: hidden;
  display: inline-block;
  left: -65px;
  top: 10px;
  cursor: pointer;
}
.revert_button img {
  height: 30px;
  width: 30px;
}
.scoring_buttons {
  display: inline-block;
  position: relative;
  top: -6px;
}
.scoring_buttons button {
  font-size: 12px;
  height: 26px;
  margin-right: 10px;
  background-color: #ffc0cb;
  color: rgb(93 124 246);
}
.save_preferences_button {
  position: relative;
  left: 56px;
  height: 25px;
  top: -21px;
  font-size: 12px;
  cursor: pointer;
  width: 39px;
}
.save_preferences_button img {
  width: 30px;
  height: 30px;
}

.rera_switch {
  margin-right: 15px;
  left: -15px;
  position: relative;
  top: -6px;
}
.rera_switch button {
  position: relative;
  left: -5px;
}

.play_buttons {
  display: inline-block;
  border: 1px solid black;
  border-radius: 8px;
  padding: 2px 10px;
  background: #00b140;
}

.search_form {
  position: relative;
  top: -4px;
  margin-right: 20px;
}

/* #viewport nav.navbar {
  display: block;
} */

.chartIcon {
  width: 25px;
  height: 25px;
  pointer-events: none;
  cursor: pointer;
}

.bodyContainer {
  float: right;
  margin-top: 0;
  margin-right: 22px;
  position: relative;
  width: 90px;
  height: 90px;
  border: 1px solid black;
  border-radius: 45px;
  left: -110px;
}
.bodyContainer.disabled span {
  position: absolute;
  z-index: 10000;
  left: 26px;
  top: -7px;
  font-size: 12px;
}
.bodyContainer img {
  opacity: 0.3;
  position: relative;
  top: 26px;
  transition: transform linear 1s;
}
.bodyContainer.disabled img {
  opacity: 0.3;
}
.bodyContainer.right {
  left: -70px;
}

#zoombandPosition {
  position: absolute;
  width: 100vw;
  z-index: 10000;
  pointer-events: none;
  height: 100%;
  left: 0;
}
#zoombandPosition.active {
  pointer-events: all;
}
#zoombandPosition.active:hover {
  background-color: #ffff3759;
}
.add_buttons {
  display: inline-block;
  position: relative;
  margin-right: 20px;
}
#add_artifact_button {
}
#zoombandPosition .title {
  transform: scale(-1, -1);
  writing-mode: vertical-rl;
  text-align: center;
}
#zoombandPosition .visibleChart {
  position: relative;
  top: 41%;
  transform: scale(1, 1);
  left: 0px;
  writing-mode: vertical-lr;
}
.signalPair {
  background-color: rgba(255, 255, 0, 0.373);
  position: absolute;
  width: 100vw;
  height: 92%;
  z-index: 1000;
  left: 0;
  display: none;
}
.chartName.active .signalPair {
  display: block;
}
.handle {
  display: inline-block;
}
.react-draggable-dragging.visibleChart,
.react-draggable-dragging.invisibleChart {
  z-index: 10;
}

#eventAddingHover {
  display: inline-block;
  margin: 0 5px;
}
button.disabled {
  cursor: not-allowed;
  pointer-events: none;
  cursor: inherit;
  color: grey;
  background-color: #ddd;
  background: #ddd;
}
.leftMenu {
  z-index: 100000;
}
.leftMenu:first-of-type {
  margin-top: -15px;
}

#event_selector_list {
  position: absolute;
  background: white;
  z-index: 100000;
  display: none;
}
#event_selector_list.show,
#event_selector_list.show2 {
  display: block;
}

#event_selector_list div {
  margin: 7px;
}

.event_selector_item {
  text-align: left;
}

@media (min-width: 1910px) {
  .study_info div {
    max-width: 22vw;
  }

  .add_buttons {
    position: relative;
  }
}

#eventContextMenu {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  box-shadow: 0px 0px 10px rgb(0, 0, 0, 0.2);
  font-size: 13px;
  padding: 0px 8px;
  display: none;
  position: fixed;
  background: white;
}
#eventContextMenu.visible {
  display: inline-block;
}

.eventContextMenu_Item {
  display: block;
  height: 40px;
  /* border-bottom: 1px solid grey; */
  padding: 10px;
  font-size: 15px;
  padding-bottom: 30px;
}

.eventContextMenu_Item:hover {
  background-color: #e0e0e0;
  cursor: pointer;
}

.revert_buttons {
  width: 120px;
  text-align: right;
  padding-right: 15px;
}

#sleepStage {
  border: 3px solid #01a2ff;
  height: 120px;
  display: none;
  position: fixed;
  pointer-events: none;
  top: 157px;
  height: calc(100% - 205px);
}
#vertLines {
  height: 100vh;
  position: fixed;
  pointer-events: none;
}
.vertLine {
  display: inline-block;
  position: fixed;
  z-index: 10000000;
  height: calc(100% - 199px);
  width: 1px;
  background: #c5c5c5;
  display: flex;
  flex-direction: column-reverse;
}
.vertLine span {
  position: relative;
  left: -18px;
  bottom: 0;
  top: -7px;
}
.vertLine span.left1 {
  left: -24px;
}
.vertLine span.left2 {
  left: -32px;
}
