h5 {
  display: block;
  color: #4e69ad;
  background-color: #cedfef;
  padding: 10px;
  font-size: 12px;
}

.event-settings-field {
  margin-bottom: 10px;
}

.event-settings-field span {
  display: inline-block;
  width: 200px;
  margin-right: 20px;
}

.event-settings-field input {
  display: inline-block;
  width: 300px;
  margin-right: 10px;
}

.event-settings-form input::-webkit-outer-spin-button,
.event-settings-form input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.event-settings-form input[type='number'] {
  -moz-appearance: textfield;
}
#waterMarks {
  position: fixed;
  top: 48%;
  left: 0;
  z-index: 1000;
}
#waterMarks div {
  position: fixed;
  top: 48%;
  font-size: 43px;
  display: inline-block;
  color: #3b3b3b8f;
}
#event_selector_list2 {
  position: relative;
  left: 65px;
  visibility: hidden;
}
.event_option {
  display: flex;
  justify-content: space-between;
}

.event_option.suboption {
  padding-left: 20px;
}
